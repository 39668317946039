/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable sonarjs/cognitive-complexity */
import { type Breakpoint } from '@/components/utility/size/show-on-size'
import { type PropsWithChildren, type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  LeftButton,
  PaginationListProvider,
  PaginatorContainer,
  RepeaterContainer,
  RightButton,
  SeeAllButton,
} from './pagination-list-components'
type BottomPaginatorStyle = 'bar' | 'dot'
interface PaginationListProps {
  maxSizeForScroll?: Breakpoint
  title: string
  headingVariant?: string
  subHeading?: JSX.Element
  seeAllTitle?: string
  seeAllLink?: string
  showBottomPagination?: boolean
  showTopPagination?: boolean
  bottomPaginatorStyle?: BottomPaginatorStyle
  margin?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  isSkeleton: boolean
  spacing?: number
  children: ReactNode
  id?: string
  testID?: string
  containerClass?: string
}

interface OuterContainerProps {
  children: ReactNode
  id?: string
  testID?: string
  margin?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  className?: string
}

const OuterContainer = ({
  children,
  margin,
  id,
  testID,
  className,
}: OuterContainerProps) => {
  let marginRepeater = 'my-8'
  switch (margin) {
    case 0:
      marginRepeater = 'my-0'
      break
    case 1:
      marginRepeater = 'my-1'
      break
    case 2:
      marginRepeater = 'my-3'
      break
    case 3:
      marginRepeater = 'my-4'
      break
    case 4:
      marginRepeater = 'my-6'
      break
    case 5:
      marginRepeater = 'my-8'
      break
    case 6:
      marginRepeater = 'my-10'
      break
    case 7:
      marginRepeater = 'my-12'
      break
    case 8:
      marginRepeater = 'my-16'
      break
    case 9:
      marginRepeater = 'my-32'
      break
    default:
      marginRepeater = 'my-8'
      break
  }

  return (
    <div
      id={id}
      data-testid={testID}
      className={twMerge(
        `relative w-full max-w-[100vw] lg:max-w-page`,
        marginRepeater,
        className,
      )}
    >
      {children}
    </div>
  )
}

const TitleText = ({
  children,
  variant,
  isSkeleton,
}: {
  children: ReactNode
  variant: 'h1' | 'h2'
  isSkeleton?: boolean
}) => {
  const componentClass = `text-2xl font-bold mx-0 flex-1`

  if (variant === 'h1')
    return (
      <h1
        data-testid={`pagination-list-title${isSkeleton ? '-skeleton' : ''}`}
        className={componentClass}
      >
        {children}
      </h1>
    )
  return (
    <h2
      data-testid={`pagination-list-title${isSkeleton ? '-skeleton' : ''}`}
      className={componentClass}
    >
      {children}
    </h2>
  )
}

const NoResults = ({ children }: { children: ReactNode }) => {
  return <div className='m-10'>{children}</div>
}

const TitleRow = ({ children }: { children: ReactNode }) => {
  return (
    <div className='mb-5 flex w-full max-sm:items-center sm:mb-3'>
      {children}
    </div>
  )
}

type TitleProps = Pick<
  PaginationListProps,
  'isSkeleton' | 'title' | 'headingVariant'
>

const Title = ({ isSkeleton, title, headingVariant }: TitleProps) => {
  return (
    <TitleText variant={headingVariant === 'h1' ? 'h1' : 'h2'}>
      {title}
    </TitleText>
  )
}

type ListHeaderProps = PropsWithChildren<TitleProps> & {
  subHeading?: JSX.Element
}
const ListHeader = ({ children, subHeading, ...props }: ListHeaderProps) => (
  <>
    <TitleRow>
      <Title {...props} />
      {children}
    </TitleRow>
    {subHeading}
  </>
)

const PaginationForwardBackContainer = ({
  children,
}: {
  children: ReactNode
}) => {
  return (
    <div className='flex flex-row items-center self-center justify-self-center'>
      {children}
    </div>
  )
}

export const RepeaterStatic = ({
  children,
  margin,
  rows = 1,
  noResultsPlaceholder,
  columns,
}: {
  children: ReactNode[]
  margin?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  rows?: number
  noResultsPlaceholder?: string | ReactNode
  columns?: number
}) => {
  let marginRepeater = 'py-5'
  switch (margin) {
    case 0:
      marginRepeater = 'py-0'
      break
    case 1:
      marginRepeater = 'py-1'
      break
    case 2:
      marginRepeater = 'py-2'
      break
    case 3:
      marginRepeater = 'py-3'
      break
    case 4:
      marginRepeater = 'py-4'
      break
    case 5:
      marginRepeater = 'py-5'
      break
    case 6:
      marginRepeater = 'py-6'
      break
    case 7:
      marginRepeater = 'py-7'
      break
    case 8:
      marginRepeater = 'py-8'
      break
    case 9:
      marginRepeater = 'py-9'
      break
    case 10:
      marginRepeater = 'py-10'
      break
    case 11:
      marginRepeater = 'py-11'
      break
    case 12:
      marginRepeater = 'py-12'
      break
    default:
      marginRepeater = 'py-5'
      break
  }

  if (children.length === 0) {
    return (
      <NoResults data-testid='no-results'>
        {noResultsPlaceholder ?? 'No Results!'}
      </NoResults>
    )
  }

  return (
    <div
      style={{
        gridTemplateColumns: `repeat(${
          columns ?? Math.ceil(children.length / rows)
        }, auto)`,
      }}
      className={`${marginRepeater} mt-2 grid w-fit max-w-full grid-flow-row gap-3 overflow-x-auto overflow-y-hidden scroll-smooth motion-reduce:scroll-auto max-lg:max-w-[calc(100%+16px)]  max-sm:max-w-[calc(100vw+8px)] md:gap-4`}
    >
      {children}
    </div>
  )
}

export const PaginationRepeater = ({
  children,
  maxSizeForScroll = 'sm',
  rows = 1,
  repeaterMargin = 0,
  noResultsPlaceholder,
  columns,
  title,
  className,
}: {
  children: ReactNode[]
  maxSizeForScroll?: Breakpoint
  rows?: number
  repeaterMargin?: 0 | 1 | 4 | 2 | 6 | 3 | 5 | 7 | 8 | 9 | 10 | 11 | 12
  noResultsPlaceholder?: string | ReactNode
  columns?: number
  title?: string
  className?: string
}) => {
  return (
    <RepeaterContainer
      data-testid='pagination-list-repeater-container'
      margin={repeaterMargin}
      maxSizeForScroll={maxSizeForScroll}
      rows={rows}
      noResultsPlaceholder={noResultsPlaceholder}
      columns={columns}
      title={title}
      className={className}
    >
      {children}
    </RepeaterContainer>
  )
}

// Main Component
export const PaginationList = ({
  title,
  headingVariant,
  subHeading,
  id,
  maxSizeForScroll = 'sm',
  seeAllTitle = 'See all',
  seeAllLink,
  showBottomPagination = false,
  bottomPaginatorStyle = 'bar',
  margin,
  isSkeleton,
  containerClass,
  children,
}: PaginationListProps) => {
  return (
    <PaginationListProvider maxSizeForScroll={maxSizeForScroll}>
      <OuterContainer id={id} margin={margin} className={containerClass}>
        <ListHeader
          isSkeleton={isSkeleton}
          title={title}
          headingVariant={headingVariant}
          subHeading={subHeading}
        >
          <PaginationForwardBackContainer>
            <>
              <LeftButton maxSizeForScroll={maxSizeForScroll} title={title} />
              <RightButton maxSizeForScroll={maxSizeForScroll} title={title} />
            </>

            {seeAllLink && (
              <SeeAllButton
                seeAllLink={seeAllLink}
                seeAllTitle={seeAllTitle}
                title={title}
              />
            )}
          </PaginationForwardBackContainer>
        </ListHeader>
        {children}
        {showBottomPagination && (
          <PaginatorContainer bottomPaginatorStyle={bottomPaginatorStyle} />
        )}
      </OuterContainer>
    </PaginationListProvider>
  )
}
