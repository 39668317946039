'use client'

import { useCallback, useEffect, useState, useTransition } from 'react'

import { useHypertune } from '@/lib/generated/hypertune.react'
import { useIsWindowBelowBreakpoint } from '@/lib/utils/use-window-size'
import dynamic from 'next/dynamic'
import { AppBreadCrumbs } from './app-bread-crumbs'
import { CategoryBar } from './category-bar'
import { GetApp } from './get-app/get-app'
import { SupportLink } from './support-link'
import { TrustPilotLink } from './trustpilot-link'

// todo: when aftership is live, remove hypertune from that component and remove this dynamic import
const AftershipBreadcrumb = dynamic(
  async () =>
    await import('../aftership/aftership-breadcrumb').then(
      (mod) => mod.AftershipBreadcrumb,
    ),
  { ssr: false },
)

export interface BreadCrumbNavBarRowProps {
  breadcrumbs?: Breadcrumb[]
  mode?: 'strip' | 'full'
}

export interface Breadcrumb {
  label: string | undefined | null
  link?: string | undefined | null
}

export const BreadCrumbNavBar = ({
  breadcrumbs,
  mode = 'full',
}: BreadCrumbNavBarRowProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_isPending, startTransaction] = useTransition()
  const isSmall = useIsWindowBelowBreakpoint('sm', 0)
  const [breadcrumbMinimised, setBreadcrumbMinimised] = useState(false)
  const hypertune = useHypertune()
  const projectBeautyEnabled = Boolean(
    hypertune.projectBeautyP1({ fallback: false }),
  )

  const handleScroll = useCallback(() => {
    if (typeof window === 'undefined') {
      return
    }

    const scrollTopOffset =
      window.pageYOffset || document.documentElement.scrollTop

    startTransaction(() => {
      if (isSmall && scrollTopOffset > 30) {
        setBreadcrumbMinimised(true)
      } else {
        setBreadcrumbMinimised(false)
      }
    })
  }, [isSmall])

  useEffect(() => {
    if (typeof window === 'undefined') return

    handleScroll()

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    if (isSmall) {
      handleScroll()
    } else {
      setBreadcrumbMinimised(false)
    }
  }, [isSmall, handleScroll])

  return (
    <>
      <div
        className={`z-[9998] w-full ${mode === 'strip' ? 'mb-6 h-auto' : projectBeautyEnabled ? 'h-16' : 'h-8'}`}
      >
        <AppBreadCrumbs breadcrumbs={breadcrumbs} />
        <div
          className={`fixed left-0 z-[9998] flex w-screen flex-col ${mode === 'full' ? 'top-[144px] sm:top-[90px]' : 'top-[32px]'} ${
            breadcrumbMinimised && mode === 'full' ? '-translate-y-6' : ''
          } transition-transform`}
        >
          {mode === 'full' ? (
            <>
              <div className='flex h-8 items-center justify-center bg-primary text-sm transition-transform'>
                <div className='flex w-full min-w-[auto] max-w-screen-lg items-center justify-between px-2 max-md:m-0 sm:px-4'>
                  <TrustPilotLink />
                  <div className='w-fit'>
                    <AftershipBreadcrumb />
                  </div>
                  <SupportLink />
                  <GetApp />
                </div>
              </div>
              {projectBeautyEnabled && <CategoryBar />}
            </>
          ) : (
            <div className='z-50 flex h-4 items-center justify-center bg-primary text-sm transition-transform' />
          )}
        </div>
      </div>
    </>
  )
}
