'use client'

import { useCallback, useEffect, useState } from 'react'

import { type Breakpoint } from '@/components/utility/size/show-on-size'
import { isClient } from './is-client'

const breakpoints = {
  xs: 0,
  sm: 616,
  md: 932,
  lg: 1280,
  xl: 1472,
}

export const useIsWindowBelowBreakpoint = (
  breakPoint: Breakpoint,
  initialWidth: number = 0,
): boolean | null => {
  const [isBelowBreakpoint, setIsBelowBreakpoint] = useState<boolean | null>(
    initialWidth === undefined ? null : initialWidth < breakpoints[breakPoint],
  )

  const resize = useCallback(() => {
    const isSmall = window.innerWidth < breakpoints[breakPoint]
    if (isSmall !== isBelowBreakpoint) {
      setIsBelowBreakpoint(isSmall)
    }
  }, [breakPoint, isBelowBreakpoint])

  useEffect(() => {
    if (isClient()) {
      resize()
      window.addEventListener('resize', resize)
      return () => {
        window.removeEventListener('resize', resize)
      }
    }
  }, [resize])

  return isBelowBreakpoint
}
