// Utility Functions

export const getCurrentPageNumbers = (ref: React.RefObject<HTMLDivElement>) => {
  if (ref.current) {
    return Math.ceil(ref.current?.scrollWidth / ref.current?.clientWidth)
  }
  return 0
}

export const scrollPaginationList = (
  ref: React.RefObject<HTMLDivElement>,
  pageNumber: number,
  offset?: number,
) => {
  ;(ref.current as HTMLDivElement).scrollTo(
    pageNumber * (ref.current as HTMLDivElement).clientWidth + (offset ?? 0),
    0,
  )
}
