'use client'

import { useMemo } from 'react'

export enum AppEnv {
  ios = 'iOS',
  android = 'Android',
}

const appStoreUrl = 'https://apps.apple.com/au/app/lasoo-catalogues/id329187735'
const playStoreUrl =
  'https://play.google.com/store/apps/details?id=au.com.lasoo.android&hl=en_AU&gl=US'

// Detect mobile OS (if applicable) by user agent
// https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
// This has false positives (IE11, Windows Phone);
// this is ok because we'd rather link to the app than not.
const getAppEnv = (): AppEnv | null => {
  if (typeof navigator !== 'undefined') {
    if (/android/i.test(navigator?.userAgent)) {
      return AppEnv.android
    }

    if (/iPad|iPhone|iPod/.test(navigator?.userAgent)) {
      return AppEnv.ios
    }
  }

  return null
}

export const useAppEnv = (): AppEnv | null => {
  const userAgent =
    typeof navigator === 'undefined' ? null : navigator.userAgent
  return useMemo(getAppEnv, [userAgent])
}

export const getAppUrl = (appEnv: AppEnv) =>
  appEnv === AppEnv.ios ? appStoreUrl : playStoreUrl
