import Link from 'next/link'
import { ResponsiveIcon } from '@/icons/responsive-icon'
import { routeCalculatorRelative } from '@/lib/route-calculator'

export const TrustPilotLink = () => (
  <Link
    href={routeCalculatorRelative.trustpilot}
    className='text-primary-contrast-text'
  >
    <div className='mr-1 flex w-fit grow-0 flex-nowrap items-center justify-start whitespace-nowrap align-middle hover:underline max-md:text-xs md:mr-2'>
      <ResponsiveIcon
        size='xs'
        mdSize='s'
        color='light'
        name='rating-star-regular'
        className='mx-1 md:mr-1'
      />
      <p className='flex w-fit leading-[18px]'>
        <strong className='mr-1 font-bold md:mx-1'>Excellent</strong> 4.3
        <span className='hidden md:inline'>&nbsp;out of 5</span>
        <span className='ml-1 hidden sm:inline'> TrustPilot</span>
      </p>
    </div>
  </Link>
)
