import { twMerge } from 'tailwind-merge'
import { type IconProps, Icon, type IconSizes } from './icon'

type ResponsiveIconProps = IconProps & {
  mdSize: IconSizes
}

export const ResponsiveIcon = ({
  className,
  size,
  mdSize,
  ...props
}: ResponsiveIconProps) => (
  <>
    <Icon {...props} size={size} className={twMerge(className, 'md:hidden')} />
    <Icon
      {...props}
      size={mdSize}
      className={twMerge(className, 'hidden md:block')}
    />
  </>
)
