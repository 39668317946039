import { type PropsWithChildren } from 'react'
import { type Breakpoint } from '@/components/utility/size/show-on-size'
import { twMerge } from 'tailwind-merge'
import {
  LeftButton,
  PaginationListProvider,
  RightButton,
  ScrollableLeft,
  ScrollableRight,
} from './pagination-list-components'

type SimplePaginationListProps = PropsWithChildren & {
  maxSizeForScroll?: Breakpoint
  containerClass?: string
}

type OuterContainerProps = PropsWithChildren & {
  id?: string
  testID?: string
  className?: string
}

const OuterContainer = ({
  children,
  id,
  testID,
  className,
}: OuterContainerProps) => {
  return (
    <div
      id={id}
      data-testid={testID}
      className={twMerge(
        `relative w-full max-w-[100vw] lg:max-w-page`,
        className,
      )}
    >
      {children}
    </div>
  )
}

export const SimplePaginationList = ({
  children,
  maxSizeForScroll = 'sm',
  containerClass,
}: SimplePaginationListProps) => {
  return (
    <PaginationListProvider maxSizeForScroll={maxSizeForScroll}>
      <OuterContainer className={twMerge('flex items-center', containerClass)}>
        <ScrollableLeft />

        <LeftButton
          maxSizeForScroll={maxSizeForScroll}
          title={'simple-pagination'}
          color='secondary'
          shouldHideWhenDisabled
        />

        {children}

        <RightButton
          maxSizeForScroll={maxSizeForScroll}
          title={'simple-pagination'}
          color='secondary'
          shouldHideWhenDisabled
        />
        <ScrollableRight />
      </OuterContainer>
    </PaginationListProvider>
  )
}
