'use client'
import { type Breakpoint } from '@/components/utility/size/show-on-size'

import {
  getCurrentPageNumbers,
  scrollPaginationList,
} from './pagination-list.utils'

import {
  ButtonIcon,
  type ButtonIconColor,
} from '@/components/elements/button-icon/button-icon'
import {
  useState,
  type ReactNode,
  useEffect,
  useCallback,
  createContext,
  useContext,
  useMemo,
  useRef,
  type RefObject,
} from 'react'
import times from 'lodash/times'
import { useIsWindowBelowBreakpoint } from '@/lib/utils/use-window-size'
import { sendGTMEvent } from '@next/third-parties/google'
import Link from 'next/link'
import { Button } from '@/components/library/button'

type BottomPaginatorStyle = 'bar' | 'dot'

interface ParamsForContainer {
  bottomPaginatorStyle?: BottomPaginatorStyle
}
interface ParamsForPaginator {
  index: number
  currentPage: number
  bottomPaginatorStyle?: BottomPaginatorStyle
}

const NoResults = ({ children }: { children: ReactNode }) => {
  return <div className='mx-0 my-2'>{children}</div>
}

const BarPaginationSelector = ({
  index,
  currentPage,
  ...props
}: ParamsForPaginator) => {
  return (
    <div
      className={`m-4 flex w-5 bg-primary-dark ${
        index === currentPage ? 'h-[5px]' : 'h-[2px]'
      }`}
      {...props}
    ></div>
  )
}

const DotPaginationSelector = ({
  index,
  currentPage,
  ...props
}: ParamsForPaginator) => {
  return (
    <div
      className={`h-2 ${
        index === currentPage ? 'w-8 bg-dark' : 'w-2 bg-dark-light'
      } m-1 rounded-s-lg`}
      {...props}
    ></div>
  )
}

const PaginationClickContainer = ({
  children,
  onClick,
}: {
  onClick: () => void
  children: ReactNode
}) => {
  return (
    <div
      onClick={onClick}
      className='flex h-5 items-end'
      data-testid='pagination-list-pagination-click-zone'
    >
      {children}
    </div>
  )
}

const PaginationSelector = ({
  index,
  currentPage,
  bottomPaginatorStyle,
}: ParamsForPaginator) =>
  bottomPaginatorStyle === 'dot' ? (
    <DotPaginationSelector
      data-testid='pagination-list-pagination-selector-dot'
      index={index}
      currentPage={currentPage}
    />
  ) : (
    <BarPaginationSelector
      data-testid='pagination-list-pagination-selector'
      index={index}
      currentPage={currentPage}
    />
  )

const useVisibility = (numberOfPages: number, maxSizeForScroll: Breakpoint) => {
  const [visibility, setVisibliity] = useState<string>('hidden')

  useEffect(() => {
    if (numberOfPages > 0) {
      switch (maxSizeForScroll) {
        case 'xs':
          setVisibliity('hidden xs:block')
          break
        case 'sm':
          setVisibliity('hidden sm:block')
          break
        case 'md':
          setVisibliity('hidden md:block')
          break
        case 'lg':
          setVisibliity('hidden lg:block')
          break
        case 'xl':
          setVisibliity('hidden xl:block')
          break
      }
    }
  }, [maxSizeForScroll, numberOfPages])

  return visibility
}

export const LeftButton = ({
  maxSizeForScroll,
  title,
  color,
  shouldHideWhenDisabled = false,
}: {
  maxSizeForScroll: Breakpoint
  title?: string
  color?: ButtonIconColor
  shouldHideWhenDisabled?: boolean
}) => {
  const { currentPage, scrollToPage, numberOfPages } = usePaginationContext()
  const visibility = useVisibility(numberOfPages, maxSizeForScroll)
  const isDisabled = currentPage <= 0

  if (shouldHideWhenDisabled && isDisabled) {
    return null
  }

  return (
    <div className='h-5'>
      <ButtonIcon
        className={visibility}
        aria-label='Scroll left'
        disabled={currentPage <= 0}
        color={currentPage > 0 ? (color ?? 'dark') : 'neutral'}
        variant={currentPage > 0 ? 'dark' : 'main'}
        iconVariant='arrow-left-1-regular'
        data-testid='pagination-list-back-button'
        onClick={() => {
          if (currentPage > 0) {
            scrollToPage(currentPage - 1, -(16 * currentPage))
          }
          sendGTMEvent({
            event: 'section_click',
            section: `leftButton_${title}`,
            location: window.location,
          })
        }}
      />
    </div>
  )
}

export const RightButton = ({
  maxSizeForScroll,
  title,
  color,
  shouldHideWhenDisabled = false,
}: {
  maxSizeForScroll: Breakpoint
  title?: string
  color?: ButtonIconColor
  shouldHideWhenDisabled?: boolean
}) => {
  const { currentPage, scrollToPage, numberOfPages } = usePaginationContext()
  const visibility = useVisibility(numberOfPages, maxSizeForScroll)
  const isDisabled = currentPage >= numberOfPages - 1

  if (shouldHideWhenDisabled && isDisabled) {
    return null
  }

  return (
    <ButtonIcon
      className={`pl-4 pr-0 ${visibility}`}
      aria-label='Scroll right'
      disabled={isDisabled}
      color={currentPage < numberOfPages - 1 ? (color ?? 'dark') : 'neutral'}
      variant={currentPage < numberOfPages - 1 ? 'dark' : 'main'}
      iconVariant='arrow-right-1-regular'
      data-testid='pagination-list-forward-button'
      onClick={() => {
        scrollToPage(currentPage + 1, 16 * (currentPage + 1))
        sendGTMEvent({
          event: 'section_click',
          section: `rightButton_${title}`,
          location: window.location,
        })
      }}
    />
  )
}

export const ScrollableLeft = ({ title }: { title?: string }) => {
  const { currentPage, scrollToPage } = usePaginationContext()
  const isDisabled = currentPage <= 0

  if (isDisabled) {
    return null
  }

  return (
    <div
      className='absolute inset-y-0 left-0 z-10 hidden w-12 bg-gradient-to-r from-black/75 to-transparent max-sm:block'
      onClick={() => {
        if (currentPage > 0) {
          scrollToPage(currentPage - 1, -(16 * currentPage))
        }
        sendGTMEvent({
          event: 'section_click',
          section: `leftButton_${title}`,
          location: window.location,
        })
      }}
    />
  )
}

export const ScrollableRight = ({ title }: { title?: string }) => {
  const { currentPage, scrollToPage, numberOfPages } = usePaginationContext()
  const isDisabled = currentPage >= numberOfPages - 1

  if (isDisabled) {
    return null
  }

  return (
    <div
      className='absolute inset-y-0 right-0 z-10 hidden w-12 bg-gradient-to-l from-black/75 to-transparent max-sm:block'
      onClick={() => {
        scrollToPage(currentPage + 1, 16 * (currentPage + 1))
        sendGTMEvent({
          event: 'section_click',
          section: `rightButton_${title}`,
          location: window.location,
        })
      }}
    />
  )
}

interface SeeAllButtonProps {
  seeAllLink: string
  seeAllTitle: string
  title?: string
}

export const SeeAllButton = ({
  seeAllLink,
  seeAllTitle,
  title,
}: SeeAllButtonProps) => (
  <Link
    prefetch={false}
    href={seeAllLink}
    onClick={() => {
      sendGTMEvent({
        event: 'section_click',
        section: `seeAllButton_${seeAllTitle}`,
        location: window.location,
      })
    }}
  >
    <Button
      size='small'
      data-testid='SeeAll'
      variant='outlined'
      color='secondary'
    >
      {seeAllTitle}
    </Button>
  </Link>
)

export const RepeaterContainer = ({
  children,
  maxSizeForScroll,
  margin,
  rows = 1,
  noResultsPlaceholder,
  columns,
  title,
  className,
  ...props
}: {
  children: ReactNode[]
  maxSizeForScroll: Breakpoint
  margin?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  rows?: number
  noResultsPlaceholder?: string | ReactNode
  columns?: number
  title?: string
  className?: string
}) => {
  const { ref } = usePaginationContext()

  let marginRepeater = 'my-5'
  switch (margin) {
    case 0:
      marginRepeater = 'my-0'
      break
    case 1:
      marginRepeater = 'my-1'
      break
    case 2:
      marginRepeater = 'my-2'
      break
    case 3:
      marginRepeater = 'my-3'
      break
    case 4:
      marginRepeater = 'my-4'
      break
    case 5:
      marginRepeater = 'my-5'
      break
    case 6:
      marginRepeater = 'my-6'
      break
    case 7:
      marginRepeater = 'my-7'
      break
    case 8:
      marginRepeater = 'my-8'
      break
    case 9:
      marginRepeater = 'my-9'
      break
    case 10:
      marginRepeater = 'my-10'
      break
    case 11:
      marginRepeater = 'my-11'
      break
    case 12:
      marginRepeater = 'my-12'
      break
    default:
      marginRepeater = 'my-5'
      break
  }

  if (children.length === 0) {
    return (
      <NoResults data-testid='no-results'>
        {noResultsPlaceholder ?? 'No Results!'}
      </NoResults>
    )
  }

  return (
    <div
      style={{
        gridTemplateColumns: `repeat(${
          columns ?? Math.ceil(children.length / rows)
        }, auto)`,
      }}
      ref={ref}
      className={`${marginRepeater} mt-2 grid w-fit max-w-full grid-flow-row gap-3 overflow-x-auto overflow-y-hidden scroll-smooth scrollbar-hide motion-reduce:scroll-auto max-lg:max-w-[calc(100%+16px)] max-sm:max-w-[calc(100vw+8px)] md:gap-x-6 ${className}`}
      {...props}
      onClick={() => {
        sendGTMEvent({
          event: 'section_click',
          section: `${title}`,
          location: window.location,
        })
      }}
    >
      {children}
    </div>
  )
}

export const PaginatorContainer = ({
  bottomPaginatorStyle,
}: ParamsForContainer) => {
  const { currentPage, numberOfPages, scrollToPage } = usePaginationContext()
  return (
    <div id='' className='flex flex-row items-end justify-center'>
      {times(numberOfPages, (index) => (
        <PaginationClickContainer
          key={index}
          onClick={() => {
            scrollToPage(index)
          }}
        >
          <PaginationSelector
            index={index}
            currentPage={currentPage}
            bottomPaginatorStyle={bottomPaginatorStyle}
          />
        </PaginationClickContainer>
      ))}
    </div>
  )
}

interface PaginationContectValues {
  currentPage: number
  numberOfPages: number
  setNumberOfPages: (numberOfPages: number) => void
  setCurrentPage: (currentPage: number) => void
  scrollToPage: (pageNumber: number, offset?: number) => void
  ref: RefObject<HTMLDivElement>
  hidePagination: boolean
  isPagination: boolean
}

const PaginationContext = createContext<PaginationContectValues>({
  currentPage: 0,
  numberOfPages: 0,
  setNumberOfPages: () => {},
  setCurrentPage: () => {},
  scrollToPage: (pageNumber: number, offset?: number) => {},
  ref: { current: null },
  hidePagination: false,
  isPagination: true,
})

const usePaginationContext = () => useContext(PaginationContext)

export const PaginationListProvider = ({
  children,
  maxSizeForScroll,
}: {
  children: ReactNode
  maxSizeForScroll: Breakpoint
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [scrollPosition, setScrollPosition] = useState(0)
  const isPagination = Boolean(useIsWindowBelowBreakpoint(maxSizeForScroll))
  const hidePagination = Boolean(isPagination && numberOfPages > 1)

  const updateScrollPos = useCallback((position: number) => {
    setScrollPosition(position)
  }, [])

  const scrollToPage = useCallback(
    (pageNumber: number, offset?: number) => {
      if (pageNumber !== currentPage) {
        setCurrentPage(pageNumber)
        scrollPaginationList(ref, pageNumber, offset)
      }
    },
    [currentPage],
  )

  const getCurrentPages = useCallback(() => {
    if (ref.current) {
      setNumberOfPages(getCurrentPageNumbers(ref))
    } else {
      setTimeout(() => {
        getCurrentPages()
      }, 10)
    }
  }, [])

  const resize = useCallback(() => {
    scrollToPage(currentPage)
  }, [currentPage, scrollToPage])

  useEffect(() => {
    resize()
  }, [resize])

  useEffect(() => {
    if (typeof window === 'undefined') return
    window.addEventListener('resize', resize)
    window.addEventListener('resize', getCurrentPages)
    getCurrentPages()
    return () => {
      window.removeEventListener('resize', resize)
      window.removeEventListener('resize', getCurrentPages)
    }
  }, [getCurrentPages, resize])

  const value = useMemo(
    () => ({
      currentPage,
      numberOfPages,
      setCurrentPage,
      setNumberOfPages,
      scrollToPage,
      updateScrollPos,
      ref,
      hidePagination,
      isPagination,
      scrollPosition,
    }),
    [
      currentPage,
      hidePagination,
      isPagination,
      numberOfPages,
      scrollPosition,
      scrollToPage,
      updateScrollPos,
    ],
  )

  return (
    <PaginationContext.Provider value={value}>
      {children}
    </PaginationContext.Provider>
  )
}
