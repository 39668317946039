import Link from 'next/link'
import { ResponsiveIcon } from '@/icons/responsive-icon'
import { routeCalculatorRelative } from '@/lib/route-calculator'

export const SupportLink = () => (
  <Link
    href={routeCalculatorRelative.support}
    className='text-primary-contrast-text'
  >
    <div className='flex cursor-pointer items-center whitespace-nowrap max-md:mr-2 max-md:text-xs'>
      <ResponsiveIcon
        size='xs'
        mdSize='s'
        color='light'
        name='headphones-customer-support-regular'
        className='mr-1 md:mr-2'
      />{' '}
      <p className='leading-[18px] text-primary-contrast-text hover:underline'>
        <span className='hidden sm:inline'>Customer </span>Support
      </p>
    </div>
  </Link>
)
