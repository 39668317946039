'use client'

import dynamic from 'next/dynamic'
import { AppEnv, getAppUrl } from './mobile-os'
import {
  type ReactNode,
  useState,
  type DetailedHTMLProps,
  type HTMLAttributes,
} from 'react'

const GetAppModal = dynamic(async () => await import('./get-app-modal'))

const AppLink = ({
  children,
  ...props
}: { children: ReactNode } & DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => {
  return (
    <div
      {...props}
      className='mx-1 text-sm font-bold text-primary-contrast-text hover:cursor-pointer hover:underline'
    >
      {children}
    </div>
  )
}

export const GetApp = () => {
  const [open, setOpen] = useState(false)
  const [appEnv, setAppEnv] = useState(AppEnv.ios)
  const [appLink, setAppLink] = useState('')

  const openModal = (appEnv: AppEnv) => {
    setOpen(true)
    setAppEnv(() => appEnv)
    setAppLink(() => getAppUrl(appEnv))
  }

  return (
    <div
      data-testid='get-app-div'
      className='mr-6 hidden w-[300px]  text-sm leading-[18px] text-primary-contrast-text md:flex'
    >
      <span>Get the app for </span>
      <AppLink
        onClick={() => {
          openModal(AppEnv.ios)
        }}
        data-testid='app-link-div'
      >
        iOS
      </AppLink>{' '}
      and{' '}
      <AppLink
        onClick={() => {
          openModal(AppEnv.android)
        }}
        data-testid='app-link-div'
      >
        Android
      </AppLink>
      {open && (
        <GetAppModal
          open={open}
          showModal={setOpen}
          appEnv={appEnv}
          appLink={appLink}
        />
      )}
    </div>
  )
}
